<template>
    <div class="content">
      <el-row class="bgW">
        <el-col :span="22">
          <!--检索条件-->
          <el-form class="search" :inline="true" label-suffix="：" size="mini" :model="search">
            <el-form-item label="门店名称" v-if="!clienId">
              <el-input
                v-model="search.shop_name"
                maxlength="30"
                class="w84"
                placeholder="请输入"
                clearable
              />
            </el-form-item>
            <el-form-item label="商品名称" v-if="!clienId">
              <el-input
                v-model="search.goods_name"
                maxlength="30"
                class="w84"
                placeholder="请输入"
                clearable
              />
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="2" style="text-align: right">
          <el-button type="primary" icon="el-icon-search" size="mini" @click="getList('search')">搜索</el-button>
        </el-col>
      </el-row>
      <div class="accountCon">
        <div class="btnBox">
          <el-button
            type="primary"
            class="mb10"
            size="mini"
            @click="tapBtn('export')"
          >导出数据</el-button>
         
        </div>
        <!--列表-->
        <el-row>
          <el-col :span="24">
            <el-table
              :data="list"
              size="small"
              border
              :cell-style="$style.cellStyle"
              :header-cell-style="$style.rowClass"
              @selection-change="handleSelectionChange"
            >
              <ElTableColumn v-if="!userInfo.shop_id" type="selection" width="50" />
              <ElTableColumn label="商品名称" prop="goods_name" />
              <ElTableColumn label="商品编码" prop="kingdee_code" />         
              <ElTableColumn label="预存总数" prop="prestock_num"  />    
              <ElTableColumn label="剩余预存数" prop="ultimately_surplus_num"  />
              <ElTableColumn label="操作" fixed="right" width="160">
                <template slot-scope="{ row }">
                  <span class="theme"  @click="tapBtn('prestock',row)">查看预存明细</span>
                  <br />
                  <span class="theme"  @click="tapBtn('refend',row)">查看出退货明细</span>
                </template>
              </ElTableColumn>
            </el-table>
            <pagination
              class="page tc mt10"
              :total="page.total"
              :page.sync="page.page"
              :limit.sync="page.limit"
              @pagination="getList"
            />
          </el-col>
        </el-row>
      </div>
    </div>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  import pagination from "@/components/Pagination";
  import { shopPrestockGoodsList,preGoodsExport } from "@/api/prestock";
  import QRCode from "qrcodejs2";
  class Page {
    total = 0;
    page = 1;
    limit = 10;
  }
  class Search {
    start_time = "";
    end_time = "";
    shop_name = "";
    goods_name = "";
  }
  class Options {

  }
  export default {
    name: "OrderList",
    components: {
      pagination
    },
    props: {
      clienId: {
        type: String,
        default: ""
      },
      detail: {
        type: Object,
        default: () => {
          return {};
        }
      }
    },
    data() {
      return {
        search: new Search(), // 检索条件
        options: new Options(), // 选项
        page: new Page(), // 分页
        list: [], // 数据展示
        idList: "",
        value1: [],
        sum_ben_amount: "noShow",
        sum_zeng_amount: "",
        orderData:{
          refund_order_no:"",
          shop_name:"",
          customer_name:"",
          pay_amount:"",
          order_time:"",
          src:"",
        },
        payData: {
          order_id:"",
          authCode:"",
        }, // 支付信息
        payQrcodePop:false,
        is_show_add:true,
      };
    },
    computed: {
      ...mapGetters(["userInfo"])
    },
    mounted() {
      this.getList();
      console.log(this.clienId)
    },
    methods: {
      // 获取列表
      getList(option) {
        if (option == "search") this.page = new Page();
        shopPrestockGoodsList({
          ...this.search,
          ...this.page,         
        }).then(res => {
          
          this.list = res.data.list;
          this.page.total = res.data.dataCount;
     
        });
      },
      // 日期搜索
      searchTime(val) {
        if (val) {
          this.search.start_time = val[0];
          this.search.end_time = val[1];
        } else {
          this.search.start_time = "";
          this.search.end_time = "";
        }
      },
      // 点击详情
      tapBtn(type, row) {
     
        switch (type) {
          case "prestock":
            const querys = { id: row.id,shop_name:this.search.shop_name };
            this.$router.push({ name: "预存货预存详情", query: querys });
            break;
          case "refend":
            const queryone = { id: row.id };
            this.$router.push({ name: "预存货出退货详情", query: queryone });
            break;
          
          case "export":
            this.hint("导出预存商品列表", "export");
            break;
         
          case "lock":
            if (this.search.end_time) {
              this.hint("锁定选中条件的订单", "lock");
            } else {
              this.$message({
                message: "请选择锁定日期",
                type: "warning"
              });
            }
            break;
        }
      },
      // 提示
      hint(text, type) {
        this.$confirm(`${text},是否确定？`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
            if (type == "export") {
              this.getExport();
            } else {
              this.getLockOrder();
            }
          }).catch(() => {
            this.$message({
              type: "info",
              message: "已取消"
            });
          });
      },
      //锁单
      getLockOrder() {
        lockOrderR({ ...this.search, type: 3 }).then(res => {
          this.$message({
            message: res.msg,
            type: "success"
          });
          this.getList();
        });
      },
      // 导出
      getExport() {
        preGoodsExport(this.search).then(res => {
          window.open(res.data.url);
        });
      },
  
      // 改变表格选中状态
      handleSelectionChange(val) {
        this.idList = val.map(i => {
          return i.id;
        });
      },
  
      // 
      printBtn(type,data){
        this.payData.order_id = data.id;
        this.payData.type = 'WeChat';
  
        getPayUrl({
          // order_id: date.id
          ...this.payData
        }).then(res=>{
          console.log(res.data);
          this.payQrcodePop = true;
          this.orderData.refund_order_no = res.data.refund_order_no;
          this.orderData.shop_name = res.data.shop_name;
          this.orderData.customer_name = res.data.customer_name;
          this.orderData.pay_amount = res.data.pay_amount;
          this.orderData.order_time = res.data.deal_time;
          this.orderData.src = "http://burn.178590.com/images/qrcode-wx.jpg";
          
          // this.printHtml();
          // this.printHtml();
        });
      },
  
      //创建二维码
      qrCodeCreate(url) {
        // console.log('生成支付二维码的链接：'+this.$refs.qrCode);
  
        this.$refs.qrCode.innerHTML = "";
  
        var qrcode = new QRCode(this.$refs.qrCode, {
          // text: 'xxxx', // 需要转换为二维码的内容
          text: url, // 需要转换为二维码的内容
          width: 150,
          height: 150,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: QRCode.CorrectLevel.H
        })
      },
  
      printHtml() {
          let bodyHtml = window.document.body.innerHTML;
          let printBox = document.getElementById('printBox').innerHTML;
          window.document.body.innerHTML = printBox;
          // console.log('333');
          window.print();
          location.reload();
          // window.document.body.innerHTML = bodyHtml;
      }
    }
  };
  </script>
  
  <style lang="scss" scoped>
  .accountCon {
    background: #fff;
    padding: 10px 0px 80px;
    .btnBox {
      display: flex;
      justify-content: space-between;
    }
  }
  .reveal {
    text-align: right;
    padding: 20px 0;
    .peril {
      font-weight: bold;
    }
  }
  </style>
  